<template>
  <div class="suggested-classes">
    <h2 class="services-title">
      RELATED TO CLASSES YOU’VE VIEWED
    </h2>
    <card-list-carousel :items="classes" :options="options"></card-list-carousel>
  </div>
</template>

<script>
import CardListCarousel from '@/components/service-carousel/CardListCarousel.vue';

export default {
  name: "suggested_classes-carousel",
  components: {
    CardListCarousel
  },
  props: {
    items: [],
  },
  data() {
    return {
      options: {
        rewind : true,
        width  : '100%',
        perPage: 3,
        gap    : '35px',
        type: 'loop',
        perMove: 1,
        pagination: false,
        breakpoints: {
          1200: {
            perPage: 2,
          },
          480: {
            perPage: 1,
          },
        }
      }
    };
  },
  created() {
    this.$store.dispatch('appointment/suggestedClasses', this.$store.state.AppActiveUser);
  },
  computed: {
    classes() {
      return this.$store.state.appointment.suggestedClasses;
    }
  },
};
</script>

<style scoped lang="scss">
  .services-title {
    color: var(--labelColor);
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 700;
    padding: 30px 30px 30px 65px;
  }
</style>
