<template>
  <div class="user-profile-container">
    <section class="maternity-class">
      <div class="maternity-class-info">
        <div class="maternity-class-schedules">
          <div class="user-name">
            Hello, Alexa!
          </div>
          <div class="maternity-classes-info">
            You can see your past and upcoming class schedules you already booked here and the reminder
            will be sent to your email.
          </div>
          <div class="classes-schedules">
            <data-table :data="classesSchedules" :headerFields="classesHeaderFields" caption="Maternity Class Schedules"></data-table>
          </div>
        </div>
      </div>
      <profile-menu></profile-menu>
    </section>
    <section class="suggested_services">
      <suggested-classes-carousel></suggested-classes-carousel>
      <interest-classes-carousel></interest-classes-carousel>
    </section>
  </div>
</template>

<script>

import ProfileMenu from '@/components/user/menu/ProfileMenu.vue';
import DataTable from "@/views/table/DataTable.vue";
import SuggestedClassesCarousel from '@/components/service-carousel/SuggestedClassesCarousel.vue';
import InterestClassesCarousel from '@/components/service-carousel/InterestClassesCarousel.vue';

export default {
  data() {
    return {
      appointmentHeaderFields: [
        'Date',
        'Time',
        'Tracking ID',
        'Notes',
        'Status'
      ],
      classesHeaderFields: [
        'Date',
        'Time',
        'Tracking ID',
        'Class Name',
        'Status'
      ],
      classesSchedules: [
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          className: "Early Pregnancy Class",
          status: "Completed",
        },
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          className: "Preparation For Breastfeeding",
          status: "Completed",
        },
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          className: "Early Pregnancy Class",
          status: "Completed",
        },
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          className: "Preparation For Breastfeeding",
          status: "Completed",
        },
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          className: "Preparation For Breastfeeding",
          status: "Completed",
        },
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          className: "Yoga & Pilates Class",
          status: "Completed",
        },
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          className: "Yoga & Pilates Class",
          status: "Completed",
        },
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          className: "Aqua Antenatal Class",
          status: "Completed",
        },
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          className: "Aqua Antenatal Class",
          status: "Completed",
        }
      ],
      appointmentSchedules: [
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          notes: "First visit - 4 weeks pregnancy",
          status: "Completed",
        },
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          notes: "First visit - 4 weeks pregnancy",
          status: "Completed",
        },
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          notes: "First visit - 4 weeks pregnancy",
          status: "Completed",
        },
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          notes: "First visit - 4 weeks pregnancy",
          status: "Completed",
        },
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          notes: "First visit - 4 weeks pregnancy",
          status: "Upcoming",
        },
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          notes: "First visit - 4 weeks pregnancy",
          status: "Upcoming",
        },
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          notes: "First visit - 4 weeks pregnancy",
          status: "Upcoming",
        },
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          notes: "First visit - 4 weeks pregnancy",
          status: "Upcoming",
        },
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          notes: "First visit - 4 weeks pregnancy",
          status: "Upcoming",
        },
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          notes: "First visit - 4 weeks pregnancy",
          status: "Completed",
        },
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          notes: "First visit - 4 weeks pregnancy",
          status: "Completed",
        }
      ]
    }
  },
  components: {
    ProfileMenu,
    DataTable,
    SuggestedClassesCarousel,
    InterestClassesCarousel
  },
  methods: {
  },
  computed: {
    currentUser () {
      return this.$store.state.AppActiveUser
    }
  },
  created() {

  }
}
</script>
<style lang="scss">
@import "@/assets/scss/variables.scss";

.user-profile-container {
  max-width: 1400px;
  margin: 0 auto;
  .maternity-class {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 35px;
    .maternity-class-info {
      padding-right: 50px;
      width: 70%;
      .maternity-class-schedules {
        .user-name {
          margin: 20px 0 5px 0;
          color: $green;
          font-size: 22px;
          font-weight: 700;
        }
        .maternity-classes-info {
          color: $lightGreen;
          margin: 20px 0 30px 0;
        }
      }
    }
    .user-menu {
      width: 30%;
    }
  }
  .suggested_services {
    margin: 0 0 120px 0;
  }
}

</style>
